import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SignUpForm from './SignUpForm';
import { Form, Button, Container, Alert } from 'react-bootstrap';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate('/signup');
  };

  const handleForgotPassword = () => {
    // Implement forgot password logic here
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://normsbackend-656b5bc5f391.herokuapp.com/users/login/', {
        email: email,
        password: password
      });
      const { success, username,token } = response.data;
      //console.log(response.data)
      if (success) {
        const user = username || email;
        //sessionStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', user);
        localStorage.setItem('Token',token)

        onLogin(user);
        navigate('/home');
      } else {
        setError('Invalid email or password');
      }
    } catch (error) {
      setError('Invalid email or password');
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center">
      <div style={{width:'30%', border:'1px solid grey', borderRadius:'7px', padding:'50px', marginTop:'100px', backgroundColor:'#F1F1F1' }}>
        <Form onSubmit={handleLogin}>
          <h2 style={{display:'flex', justifyContent:'center'}}>Login</h2>
          <hr />
          <Form.Group controlId="email">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password:</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <a href="#" onClick={handleForgotPassword} style={{float: 'right' }}>Forgot Password?</a>
          <br />
          <div style={{marginTop:'20px'}}>
          <Button variant="primary" type="submit" style={{marginRight:'50px'}}>Login</Button>
          <Button variant="success" onClick={handleSignup} style={{marginLeft:'80px'}}>Sign Up</Button>
          </div>
        </Form>
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
    </Container>

  );
};

export default Login;
